import {EditorSDK} from '@wix/editor-platform-sdk-types';
import {AppManifestBuilder} from '@wix/app-manifest-builder';
import {LocaleKeys} from '../../__generated__/LocaleKeys';

const HELP_ID = '4bf6919c-d2a4-45d4-99cb-a0928bd7e08d';
const MANAGE_PRODUCTS_TOKEN = 'grid-gallery-manage-products';
const SETTINGS_PANEL_TOKEN = 'grid-gallery-settings-panel';

const baseSettingsPanelOptions: Partial<Parameters<EditorSDK['editor']['openComponentPanel']>[1]> = {
  width: 404,
  height: 583,
  type: 'settings',
  helpId: HELP_ID,
};

const bindConfiguratorToWidgetId =
  ({widgetId, settingsTitle}: {widgetId: string; settingsTitle: string}) =>
  ({t, editorSDK}: {t: (key: string) => string; editorSDK: EditorSDK}) =>
  (appManifestBuilder: AppManifestBuilder): AppManifestBuilder =>
    appManifestBuilder.configureWidget(widgetId, (widgetBuilder) =>
      widgetBuilder
        .gfpp()
        .set('help', {
          id: HELP_ID,
        })
        .set('settings', {
          onClick: async (event) =>
            editorSDK.editor.openComponentPanel(SETTINGS_PANEL_TOKEN, {
              ...baseSettingsPanelOptions,
              title: t(settingsTitle),
              url: 'https://ecom.wix.com/storefront/settings-gridgallery',
              componentRef: event.detail.componentRef,
            }),
        })
        .set('mainAction1', {
          label: t(LocaleKeys().settings.shopPage.gfpp.mainAction.manageProducts),
          onClick: () =>
            editorSDK.editor.openDashboardPanel(MANAGE_PRODUCTS_TOKEN, {
              url: 'store/products',
              closeOtherPanels: true,
            }),
        })
        .set('mainAction2', {
          label: t(LocaleKeys().settings.shopPage.gfpp.mainAction.chooseCollection),
          onClick: (event) =>
            editorSDK.editor.openComponentPanel(SETTINGS_PANEL_TOKEN, {
              ...baseSettingsPanelOptions,
              title: t(settingsTitle),
              url: 'https://ecom.wix.com/storefront/settings-gridgallery/collection',
              componentRef: event.detail.componentRef,
            }),
        })
    );

export const configureStorePageGfpp = bindConfiguratorToWidgetId({
  widgetId: '1380bba0-253e-a800-a235-88821cf3f8a4',
  settingsTitle: LocaleKeys().settings.shopPage.common.panelHeader,
});

export const configureGridGalleryGfpp = bindConfiguratorToWidgetId({
  widgetId: '13afb094-84f9-739f-44fd-78d036adb028',
  settingsTitle: LocaleKeys().settings.gridGallery.common.panelHeader,
});
