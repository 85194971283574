import {Action, ArticleId, currencyConverterAppDefID, ecomAppDefID, Events, SPECS} from '../../editor-script/constants';
import {PageMap} from '@wix/wixstores-client-core/dist/src/constants';
import {getAppVersion} from '@wix/wixstores-client-core/dist/src/app-version/appVersion';
import {Experiments} from '../experiments/Experiments';
import {AppManifestBuilder, AppManifest} from '@wix/app-manifest-builder';
import {EditorSDK} from '@wix/platform-editor-sdk';
import {configureGridGalleryGfpp, configureStorePageGfpp} from './configureGridGalleryGfpp';

export function getAppManifest({
  t,
  locale,
  token,
  hasStoresPremium,
  experiments,
  appManifestBuilder,
  editorSDK,
  options,
}: {
  t: (key: string) => string;
  locale: string;
  token: string;
  hasStoresPremium: boolean;
  experiments: Experiments;
  appManifestBuilder: AppManifestBuilder;
  editorSDK: EditorSDK;
  options: IEditorOptions;
}) {
  const version = getAppVersion();

  const customActions = [
    {
      title: t('App_Manager_Stores_First_Quick_Action'),
      actionId: Action.openAddPanel,
      icon: 'appManager_addElementsAction',
      type: 'editorActions',
    },
    {
      title: t('App_Manager_Stores_Second_Quick_Action'),
      actionId: Action.openPagesPanel,
      icon: 'appManager_pagesAction',
      type: 'editorActions',
    },
    {
      title: t('App_Manager_Stores_Fourth_Quick_Action'),
      actionId: Action.addRelatedApps,
      icon: 'appManager_relatedAppsAction',
      type: 'editorActions',
    },
  ];

  if (locale === 'en') {
    customActions.splice(2, 0, {
      title: t('App_Manager_Stores_Third_Quick_Action'),
      actionId: Action.findProductsToSell,
      icon: 'market',
      type: 'editorActions',
    });
  }

  const pageActions = {
    default: [
      'Pages_Actions_Page_Rename',
      {
        title: t('Pages_Panel_ShopPage_Settings_Delete'),
        event: Events.deletePage,
        icon: 'deleteAction',
        type: 'page_remove',
      },
    ],
    [PageMap.PRODUCT]: [],
    [PageMap.CART]: [],
    [PageMap.THANKYOU]: [],
  };

  const pageSettings = {
    default: [
      {
        helpId: ArticleId.storesPages,
        type: 'page_info',
      },
      {
        type: 'layout',
      },
      {
        type: 'permissions',
      },
      {
        title: t('Pages_Panel_SEO'),
        type: 'seo',
      },
    ],
    [PageMap.PRODUCT]: [
      {
        helpId: ArticleId.storesPages,
        type: 'page_info',
        title: t('Pages_Panel_pageInfo'),
        url: `https://static.parastorage.com/services/wixstores-client-worker/${version}/info-pages/productPage_${locale}.html?token=${token}`,
      },
      {
        type: 'layout',
      },
      {
        type: 'permissions',
      },
    ],
    [PageMap.CART]: [
      {
        helpId: ArticleId.storesPages,
        type: 'page_info',
        title: t('Pages_Panel_pageInfo'),
        url: `https://static.parastorage.com/services/wixstores-client-worker/${version}/info-pages/cart_${locale}.html`,
      },
      {
        type: 'layout',
      },
      {
        type: 'permissions',
      },
    ],
    [PageMap.THANKYOU]: [
      {
        helpId: ArticleId.storesPages,
        type: 'page_info',
        title: t('Pages_Panel_pageInfo'),
        url: `https://static.parastorage.com/services/wixstores-client-worker/${version}/info-pages/thankYouPage_${locale}.html`,
      },
      {
        type: 'layout',
      },
      {
        type: 'permissions',
      },
    ],
  };

  const applicationSettings = {
    default: {
      displayName: t('Pages_Panel_storesPages'),
      helpId: ArticleId.storesPages,
    },
  };

  const applicationActions = {
    default: {
      defaultValues: [
        {
          title: t('Pages_Panel_manageCTA'),
          event: Events.manageStores,
          icon: 'settingsAction',
        },
      ],
    },
  };

  if (experiments.enabled('specs.stores.AddShopPage')) {
    (applicationActions.default.defaultValues as any).push({
      title: t('NewPages_Panel_StorePages_Panel_AddShopPage_General'),
      event: Events.addShopPage,
      icon: 'addPagePagesPanel',
      type: 'add_page',
    });
  }

  const pageDescriptors = {
    default: {
      icon: 'storesPageType',
      orderIndex: 2,
    },
    [PageMap.PRODUCT]: {
      icon: 'storesPageType',
      orderIndex: 3,
      tooltip: t('Pages_Store_Product_Info_WhatisText'),
    },
    [PageMap.CART]: {
      icon: 'storesPageType',
      orderIndex: 1,
      tooltip: t('Pages_Store_Cart_Info_WhatisText'),
    },
    [PageMap.THANKYOU]: {
      icon: 'storesPageType',
      orderIndex: 0,
      tooltip: t('Pages_Store_Thankyou_Info_WhatisText'),
    },
  };

  const appDescriptor = {
    mainActions: [
      {
        title: t('App_Manager_Stores_Main_CTA'),
        actionId: Action.openManageProducts,
        icon: 'variations',
      },
      {
        title: t('App_Manager_Stores_Secondary_CTA'),
        actionId: Action.openDashboard,
        icon: 'appManager_settingsAction',
      },
    ],
    customActions,
    defaultActions: {
      upgrade: hasStoresPremium
        ? {}
        : {
            upgradeType: 'SITE_UPGRADE',
            upgradeText: t('App_Manager_Stores_Upgrade_Text'),
            upgradeLinkText: t('App_Manager_Stores_Upgrade_Link'),
          },
      learnMoreKB: ArticleId.stores,
    },
  };

  const isClassicEditor = options.origin.type === 'CLASSIC';
  const isBuildManifestExperiment = experiments.enabled(SPECS.GalleryNewGFPP);

  const appManifest =
    isClassicEditor && isBuildManifestExperiment
      ? pipe(
          configureGridGalleryGfpp({t, editorSDK}),
          configureStorePageGfpp({t, editorSDK})
        )(appManifestBuilder).build()
      : {};

  appManifest.pages = {
    pageActions,
    pageSettings,
    applicationSettings,
    applicationActions,
    pageDescriptors,
  } as unknown as AppManifest['pages'];

  appManifest.appDescriptor = appDescriptor as unknown as AppManifest['appDescriptor'];

  return appManifest;
}

export function handleAppsManagerActions(sdk: IEditorSdk, action: Action) {
  switch (action) {
    case Action.openDashboard:
      return sdk.editor.openDashboardPanel('', {url: '', closeOtherPanels: false});
    case Action.openManageProducts:
      return sdk.editor.openDashboardPanel('', {url: 'store/products', closeOtherPanels: false});
    case Action.openAddPanel:
      return sdk.editor.deeplink.show('', {type: 'addPanel', params: [ecomAppDefID]});
    case Action.openPagesPanel:
      return sdk.editor.deeplink.show('', {type: 'pagesPanel', params: [ecomAppDefID]});
    case Action.findProductsToSell:
      return sdk.editor.openDashboardPanel('', {url: 'store/explore-products', closeOtherPanels: false});
    case Action.addRelatedApps:
      return sdk.editor.deeplink.show('', {type: 'addPanel', params: [currencyConverterAppDefID]});
  }
}

function pipe<D>(...funcs: ((param: D) => D)[]): (param: D) => D {
  return (initialUInput) => funcs.reduce((result, currentFn) => currentFn(result), initialUInput);
}
